/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { AppProvider } from "./src/store/context/app-context"
import { MenuProvider } from "./src/store/context/menu-context"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
export const wrapRootElement = ({ element }) => {
  return (
    <MenuProvider>
      <AppProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {element}
      </AppProvider>
    </MenuProvider>
  )
}
