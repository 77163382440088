import React from "react"
const AppStateContext = React.createContext()
const AppDispatchContext = React.createContext()
function appReducer(state, action) {
  switch (action.type) {
    case "SET_HEIGHT": {
      return {
        data: { ...state.data, height: action.payload },
      }
    }
    case "SET_POSITION": {
      return {
        data: { ...state.data, position: action.payload },
      }
    }
    case "SET_VARIANT": {
      return {
        data: { ...state.data, variant: action.payload },
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(appReducer, {
    data: null,
  })
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}
function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider")
  }
  return context
}
function useAppDispatch() {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider")
  }
  return context
}
export { AppProvider, useAppState, useAppDispatch }
